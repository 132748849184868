<template>
    <div>
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <div class="d-flex align-center pt-3">
                    <v-text-field
                        :placeholder="$t('message.no')"
                        :value="searchValues.title"
                        autocomplete="new-password"
                        class="filter-width-200 force-text-left"
                        clearable
                        dense
                        hide-details="auto"
                        append-icon="search"
                        solo
                        @change="searchValues.title = $event"
                        @click:append="searchPackingLists('title')"
                        @click:clear="resetSearch()"
                        @keyup.enter.prevent="searchPackingLists('title')"
                    />
                    <v-text-field
                        :placeholder="$t('message.plNo')"
                        :value="searchValues.pl"
                        autocomplete="new-password"
                        class="filter-width-200 force-text-left ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        append-icon="search"
                        solo
                        @change="searchValues.pl = $event"
                        @click:append="searchPackingLists('pl')"
                        @click:clear="resetSearch()"
                        @keyup.enter.prevent="searchPackingLists('pl')"
                    />
                    <v-autocomplete
                        :items="filterItems.buyer"
                        :loading="loading.filterItems.buyer"
                        :placeholder="$t('message.buyer')"
                        class="filter-width-200 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="Customer.title"
                        item-value="Customer.id"
                        solo
                        v-model="filterValues.buyer"
                        @change="filterPackingLists()"
                    />
                    <v-btn
                        :loading="loading.clear"
                        class="ml-3 mt-0"
                        @click="clearFilters"
                    >{{ $t('message.clear') }}</v-btn>
                </div>
                <v-spacer/>
                <ExportTableJsonWithAdd
                    :export-conditions="exportConditions"
                    :export-data="PackingLists"
                    :export-fields="headers"
                    :export-source="'packinglists'"
                    :has-add-function="true"
                    class="mt-3"
                    @add-action="newPackingList"
                />
                <HelpPopUpV2 help-page-component="PackingListsListing"/>
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loading.packinglists"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: itemsPerPageOptions,
                showFirstLastPage: true
            }"
            :headers="headers"
            :hide-default-footer="hideDefaultFooter"
            :items="PackingLists"
            :options.sync="tableOptions"
            :server-items-length="totalPackingLists"
            calculate-widths
            class="mt-3 appic-table-light specification-table"
            dense
            id="packingListTable"
            item-key="Pldo.id"
            @update:options="updateDataTable"
        >
            <template v-slot:item.Pldo.Contract.contractdate="{ item }">
                {{ item.Pldo.Contract.contractdate != '0000-00-00' ? formatDate(item.Pldo.Contract.contractdate) : '' }}
            </template>
            <template v-slot:item.Pldo.Contract.title="{ item }">
                <span class="font-weight-bold">{{ item.Pldo.Contract.title }}</span>
            </template>
            <template v-slot:item.Pldo.title="{ item }">
                <span class="font-weight-bold">{{ item.Pldo.title }}</span>
            </template>
            <template v-slot:item.Pldo.pldodate="{ item }">
                {{ item.Pldo.pldodate != '0000-00-00' ? formatDate(item.Pldo.pldodate) : '' }}
            </template>
            <template v-slot:item.Pldo.Customer.title="{ item }">
                <div class="text-no-wrap">{{ item.Pldo.Customer.otsname != '' ? item.Pldo.Customer.otsname : item.Pldo.Customer.title }}</div>
            </template>
            <template v-slot:item.Pldo.Contract.etddate="{ item }">
                {{ item.Pldo.Contract.etddate != '0000-00-00' ? formatDate(item.Pldo.Contract.etddate) : '' }}
            </template>
            <template v-slot:item.Pldo.Contract.etadate="{ item }">
                {{ item.Pldo.Contract.etadate != '0000-00-00' ? formatDate(item.Pldo.Contract.etadate) : '' }}
            </template>
            <template v-slot:item.Pldo.Contract.containersize_id="{ item }">
                <div class="text-no-wrap">
                    <span v-if=" [1,2,3,5].includes(item.Pldo.Contract.containersize_id) ">{{ item.Pldo.Contract.containercount }}</span>
                    <span v-if=" [1,2,3,5].includes(item.Pldo.Contract.containersize_id) "> X </span>
                    <span>{{ getContainerSize(item.Pldo.Contract.containersize_id) }}</span>
                </div>
            </template>
            <template v-slot:item.Pldo.Contract.shipmentstatus_id="{ item }">
                <span class="text-no-wrap" v-if="item.Pldo.Contract.shipmentstatus_id == 6">
                    <v-icon x-small color="grey" class="mr-1">fas fa-ship</v-icon><span :class="item.Pldo.Contract.shipmentstatus_text && item.Pldo.Contract.shipmentstatus_text.length > 10 ? 'font-xs' : ''">{{ getShipmentStatus(item.Pldo.Contract.shipmentstatus_id, item.Pldo.Contract.shipmentstatus_text) }}</span>
                </span>
            </template>
            <template v-slot:item.Pldo.Total.bundles="{ item }">
                <div>{{ item.Pldo.version != null ? item.Pldo.Total.bundles : $t('message.na') }}</div>
            </template>
            <template v-slot:item.Pldo.Total.pieces="{ item }">
                <div>{{ item.Pldo.version != null ? item.Pldo.Total.pieces : $t('message.na') }}</div>
            </template>
            <template v-slot:item.Pldo.Total.volume="{ item }">
                <span class="text-no-wrap">{{ item.Pldo.version != null ? formatThisNumber(item.Pldo.Total.volume,'0,0.00') : $t('message.na') }}</span>
            </template>
            <template v-slot:item.Pldo.grossweight="{ item }">
                <span class="text-no-wrap">{{ item.Pldo.version != null ? formatThisNumber(item.Pldo.grossweight,uofmWeights.find(uofm=>uofm.Measurement.id == item.Pldo.gwmeasurement_id).Measurement.formatPrecision) + ' ' + uofmWeights.find(uofm=>uofm.Measurement.id == item.Pldo.gwmeasurement_id).Measurement.title : item.Pldo.grossweight.toUpperCase() }}</span>
            </template>
            <template v-slot:item.Pldo.netweight="{ item }">
                <span class="text-no-wrap">{{ item.Pldo.version != null ? formatThisNumber(item.Pldo.netweight,uofmWeights.find(uofm=>uofm.Measurement.id == item.Pldo.nwmeasurement_id).Measurement.formatPrecision) + ' ' + uofmWeights.find(uofm=>uofm.Measurement.id == item.Pldo.nwmeasurement_id).Measurement.title : item.Pldo.netweight.toUpperCase() }}</span>
            </template>
            <template v-slot:item.Pldo.id="{ item }">
                <v-menu>
                    <template v-slot:activator="{ on: menu }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                    <v-icon>more_vert</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('message.moreActions') }}</span>
                        </v-tooltip>
                    </template>
                    <v-list dense>
                        <v-list-item class="font-sm" @click="updatePackingList(item.Pldo.id)"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updatePackingList') }}</v-list-item>
                        <v-list-item class="font-sm" @click="cancelPackingList(item.Pldo.id, item.Pldo.title)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.cancelPackingList') }}</v-list-item>
<!--                        <v-list-item class="font-sm" @click="viewPdf(item.Pldo.id, item.Pldo.version)"><v-icon color="red lighten-1" small class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.pdf') }}</v-list-item>-->
                        <PrintPackingListButton
                            :packing-list-id="item.Pldo.id"
                            :update-mode="true"
                            :list-mode="true"
                        />
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>
        <SimpleAlert
            :alert_message.sync="dialogs.error_message"
            :dialog.sync="dialogs.error"
            @dialog-closed="dialogClosed"
        ></SimpleAlert>
    </div>
</template>

<script>
    import {formatDate} from "Helpers/helpers";
    // import SimpleAlert from "./SimpleAlert";
    import {numberFormat} from "../../helpers/helpers";
    import {mapGetters, mapActions} from "vuex";
    import {mapFields, mapMultiRowFields} from "vuex-map-fields";
    import { v4 as uuidv4 } from 'uuid';
    import {api} from "Api";

    const ExportTableJsonWithAdd = () => import("./ExportTableJsonWithAdd");
    const SimpleAlert = () => import("./SimpleAlert");
    const HelpPopUpV2 = () => import("Components/Appic/Help/HelpPopUpV2");
    const PrintPackingListButton = () => import("./Printing/PrintPackingListButton.vue");

    export default {
        name: "PackingListsListingNew",
        components: {PrintPackingListButton, HelpPopUpV2, ExportTableJsonWithAdd, SimpleAlert},
        data() {
            return {
                currentFilter: null,
                currentSearch: null,
                dialogs: {
                    error:  false,
                    error_message: ""
                },
                exportConditions: {},
                filterItems: {
                    buyer: []
                },
                filterOptions: {
                    status: {
                        Status: {
                            id: 1,
                            title: 'open'
                        }
                    }
                },
                filterValues: {
                    buyer: null
                },
                hideDefaultHeader: false,
                hideDefaultFooter: false,
                loading: {
                    filter: {
                        buyers: false
                    },
                    filterItems: {
                        buyer: null
                    },
                    search: false,
                    packinglists: false
                },
                searchField: 'Pldo.Contract.title', //default search field
                searchTerm: null,
                searchValues: {
                    pl: null,
                    title: null
                },
                tableOptions: {},
            }
        },
        computed: {
            ...mapMultiRowFields('packinglist',{
                PackingLists: 'current.PackingLists'
            }),
            ...mapFields('packinglist',{
                totalPackingLists: 'current.totalPackingLists'
            }),
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
            ...mapGetters([
                'containerSizes',
                'currencies',
                'shipmentStatuses',
                'uofmDimensions',
                'uofmQty',
                'uofmVolumes',
                'uofmWeights'
            ]),
            console: () => console,
            defaultItemsPerPage() {
                if(window.screen.height >= 800) return 15
                return 10
            },
            formatDate: () => formatDate,
            headers() {
                return [
                    {
                        id: 1,
                        text: this.$t('message.actions'),
                        value: 'Pldo.id',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                        sortable: false
                    },
                    {
                        id: 2,
                        text: this.$t('message.date'),
                        value: 'Pldo.Contract.contractdate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
                    },
                    {
                        id: 3,
                        text: this.$t('message.number'),
                        value: 'Pldo.Contract.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
                    },

                    {
                        id: 4,
                        text: this.$t('message.buyer'),
                        value: 'Pldo.Customer.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
                    },
                    {
                        id: 5,
                        text: this.$t('message.plNumber'),
                        value: 'Pldo.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
                    },
                    {
                        id: 6,
                        text: this.$t('message.plDate'),
                        value: 'Pldo.pldodate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
                    },
                    {
                        id: 7,
                        text: this.$t('message.container'),
                        value: 'Pldo.Contract.containersize_id',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false
                    },
                    {
                        id: 8,
                        text: this.$t('message.packages'),
                        value: 'Pldo.Total.bundles',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false
                    },
                    {
                        id: 9,
                        text: this.$t('message.pcs'),
                        value: 'Pldo.Total.pieces',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false
                    },
                    {
                        id: 10,
                        text: this.$t('message.grossWeight'),
                        value: 'Pldo.grossweight',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false
                    },
                    {
                        id: 11,
                        text: this.$t('message.netWeight'),
                        value: 'Pldo.netweight',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false
                    },
                    {
                        id: 12,
                        text: this.$t('message.shipmentStatus'),
                        value: 'Pldo.Contract.shipmentstatus_id',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false
                    },
                    {
                        id: 13,
                        text: this.$t('message.etd'),
                        value: 'Pldo.Contract.etddate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
                    },
                    {
                        id: 14,
                        text: this.$t('message.eta'),
                        value: 'Pldo.Contract.etadate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
                    }
                ]
            },
            itemsPerPageOptions() {
                let options = [10,20,50,-1];
                if(window.screen.height >= 800){
                    options = [15,30,50,-1];
                }
                return options;
            },
            searchFields() {
                return this.headers.filter((header)=>header.searchable === true)
            },
            years() {
                let years = []
                let dt = new Date()
                let startYear = dt.getFullYear()
                let endYear = 2017
                for(let i = startYear; i >= endYear; i--){
                    years.push(i)
                }
                return years
            }
        },
        methods: {
            ...mapActions('packinglist',{
                cancelPackingListById: 'cancelPackingListById',
                getAllPackingLists: 'getAllPackingLists',
                searchAllPackingLists: 'searchAllPackingLists'
            }),
            async cancelPackingList (val, title) {
                if(await this.$root.$confirm(this.$t('message.cancelPackingList') + ' ' + title, this.$t('message.confirmations.continuePackingListCancelAction'), {color: 'orange'})){
                    this.cancelPackingListById(val)
                        .then((response) => {
                            if(response.status == 'success'){
                                this.$toast.success(this.$t('message.successes.packingListDeleted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.loadPackingLists()
                            } else {
                                this.$toast.error(this.$t('message.errors.packingListNotDeleted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                        })
                        .catch(()=>{
                            this.$toast.error(this.$t('message.errors.packingListNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                }
            },
            clearFilters() {
                this.loading.clear = true
                this.filterValues.buyer = null
                this.searching = false
                this.searchValues.pl = null
                this.searchValues.title = null
                this.tableOptions.page = 1
                this.tableOptions.sortBy[0] = 'Pldo.pldodate'
                this.tableOptions.sortDesc[0] = true
                this.loading.clear = false
                this.filterPackingLists()
            },
            dialogClosed () {
                this.dialogs.error = false;
                this.dialogs.error_message = ''
            },
            filterPackingLists() {
                this.searchValues.pl = null
                this.searchValues.title = null
                let noValues = true
                const keys = Object.keys(this.filterValues)
                keys.forEach((key, index) => {
                    if(this.filterValues[key] != null) noValues = false
                })
                if(noValues == false) {
                    let conditions = []
                    if(this.filterValues.buyer != null) conditions.push({field: 'Pldo.Contract.customer_id', value: this.filterValues.buyer})

                    if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = this.defaultItemsPerPage

                    if(_.isEqual(this.currentFilter,this.filterValues) === false) {
                        this.tableOptions.page = 1
                        this.currentFilter = _.cloneDeep(this.filterValues)
                    }

                    if(_.has(this.tableOptions, 'sortBy') == false) {
                        this.tableOptions['sortBy'] = ['Pldo.pldodate']
                    }
                    if(this.tableOptions.sortBy.length == 0){
                        this.tableOptions['sortBy'] = ['Pldo.pldodate']
                    }
                    if(_.has(this.tableOptions, 'sortDesc') == false){
                        this.tableOptions['sortDesc'] = [false]
                        if(this.tableOptions.sortBy[0] == 'Pldo.pldodate') {
                            this.tableOptions['sortDesc'] = [true]
                        }
                    }
                    if(this.tableOptions.sortBy[0] == 'Pldo.pldodate' && this.tableOptions.sortDesc.length == 0){
                        this.tableOptions['sortDesc'] = [true]
                    }

                    let order = [{field: this.tableOptions.sortBy[0], direction: this.tableOptions.sortDesc[0] ? 'DESC' : 'ASC'}]

                    let payload = {
                        tableOptions: this.tableOptions,
                        conditions: conditions,
                        order: order
                    }

                    this.hideDefaultFooter = false
                    this.loading.packinglists = true;
                    this.searchAllPackingLists(payload)
                        .then(() => {
                            this.loading.packinglists = false;
                        })
                        .catch(()=>{
                            this.loading.packinglists = false;
                        })
                        .finally(()=>{
                            this.loading.packinglists = false;
                        })
                } else {
                    // this.loadPackingLists()
                    this.reloadPackingLists()
                        .then(() => {
                            this.loading.packinglists = false;
                        })
                        .catch(() => {
                            this.loading.packinglists = false;
                        })
                }
            },
            formatThisNumber(value,format){
                return numberFormat(value,format)
            },
            getContainerSize (val) {
                let container = this.containerSizes.find(containerSize => containerSize.Containersize.id == val)
                if(container){
                    return container.Containersize.title
                } else {
                    return null
                }
            },
            getShipmentStatus (id, text) {
                if(id) {
                    let status = this.shipmentStatuses.find(d => d.Shipmentstatus.id == id)
                    if(status?.Shipmentstatus?.hastext == 1){
                        if(id == 1){
                            if(parseInt(text) == text){
                                return status?.Shipmentstatus?.title + ': ' + text
                            } else {
                                return text
                            }
                        } else {
                            return text
                        }
                    } else {
                        return status?.Shipmentstatus?.title
                    }
                }
                return null
            },
            loadFilterItems(filter) {
                return new Promise((resolve, reject) => {
                    if(filter && filter.length > 0) {
                        this.loading.filterItems[filter] = true
                        api
                            .get('/packinglists/filter-options/' + filter)
                            .then(response => {
                                if(response.data.status == 'success') {
                                    this.filterItems[filter] = response.data.data
                                    this.loading.filterItems[filter] = false
                                    resolve('done')
                                } else {
                                    this.loading.filterItems[filter] = false
                                    reject('error')
                                }
                            })
                            .catch(error => {
                                this.loading.filterItems[filter] = false
                                reject(error)
                            })
                    } else {
                        reject('Filter empty')
                    }
                })
            },
            loadPackingLists () {
                this.hideDefaultFooter = false
                if(!this.loading.packinglists) this.loading.packinglists = true;

                if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = this.defaultItemsPerPage

                let payload = {
                    tableOptions: this.tableOptions,
                    filterOptions: this.filterOptions
                }
                this.getAllPackingLists(payload)
                    .then(()=>{
                        this.loading.packinglists = false;
                    })
                    .catch(()=>{
                        this.loading.packinglists = false;
                    })
            },
            reloadPackingLists () {
                return new Promise((resolve, reject) => {
                    try {
                        this.hideDefaultFooter = false
                        if(!this.loading.packinglists) this.loading.packinglists = true;

                        if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = this.defaultItemsPerPage

                        const payload = {
                            tableOptions: this.tableOptions
                        }

                        this.getAllPackingLists(payload)
                            .then(() => {
                                // this.loading.packinglists = false;
                                resolve('done')
                            })
                            .catch(()=>{
                                // this.loading.packinglists = false;
                                reject('error')
                            })
                    } catch (error){
                        reject('error')
                    }
                })
            },
            newPackingList() {
                let tab = window.open('/v1/packinglists/add', '_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            resetSearch(filter = null) {
                this.searching = false
                this.hideDefaultFooter = false
                this.filterValues.buyer = null
                this.tableOptions.page = 1
                if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = this.defaultItemsPerPage

                switch(filter){
                    case 'eta':
                    case 'etd':
                    case 'ets':
                    case 'osd':
                        this.searchValues[filter] = []
                        break
                }

                this.tableOptions.sortBy = []
                this.tableOptions.sortDesc = []
                this.filterPackingLists()
            },
            searchPackingLists(field) {
                if(this.searchValues[field] == null || this.searchValues[field].length == 0){
                    this.dialogs.error = true
                    this.dialogs.error_message = this.$t('message.errors.noSearchTerm')
                } else {
                    //TODO search packing lists
                    // this.filterOptions = {}
                    // this.hideDefaultFooter = true
                    // this.loading.packinglists = true;
                    // this.loading.search = true;
                    // let payload = {
                    //     tableOptions: this.tableOptions,
                    //     search: {
                    //         field: this.searchField,
                    //         value: this.searchTerm
                    //     }
                    // }

                    this.searching = true
                    this.filterValues.buyer = null

                    const keys = Object.keys(this.searchValues)
                    keys.forEach((key, index) => {
                        if(key != field) {
                            this.searchValues[key] = null
                        }
                    })

                    let fieldName = null
                    let sortFieldName = null
                    switch(field){
                        case 'pl':
                            fieldName = 'Pldo.title'
                            sortFieldName = 'Pldo.pldodate'
                            break
                        case 'title':
                            fieldName = 'Pldo.Contract.title'
                            sortFieldName = 'Pldo.Contract.contractdate'
                            break
                    }

                    this.loading.packinglists = true

                    let payload = {}
                    let order = []

                    if (_.isEqual(this.currentSearch, this.searchValues) === false) {
                        //new search = new sort
                        order.push({field: fieldName, direction: ['pl','title'].includes(field) ? 'ASC' : 'DESC'})
                        this.tableOptions.sortBy[0] = sortFieldName
                        this.tableOptions.sortDesc[0] = ['pl','title'].includes(field) ? false : true
                        this.currentSearch = _.cloneDeep(this.searchValues)
                        //new search new page
                        this.tableOptions.page = 1
                    } else {
                        //same search new sort
                        if(this.tableOptions.sortBy.length == 0){
                            this.tableOptions.sortBy[0] = sortFieldName
                        }
                        order.push({field: this.tableOptions.sortBy[0], direction: this.tableOptions.sortDesc[0] ? 'DESC' : 'ASC'})
                    }

                    if(fieldName != null) {
                        payload = {
                            tableOptions: this.tableOptions,
                            conditions: [
                                {
                                    field: fieldName,
                                    value: this.searchValues[field]
                                }
                            ],
                            order: order
                        }
                    } else {
                        payload = {
                            tableOptions: this.tableOptions,
                            conditions: [],
                            order: order
                        }
                    }
                    this.searchAllPackingLists(payload)
                        .then(() => {
                            this.loading.packinglists = false;
                        })
                        .catch(()=>{
                            this.loading.packinglists = false;
                        })
                        .finally(()=>{
                            this.loading.packinglists = false;
                        })
                }
            },
            updateDataTable(options) {
                this.tableOptions = options
                if(this.searching === true)  {
                    // check which field is not empty
                    let field = null
                    if(this.searchValues.pl != null) field = 'pl'
                    if(this.searchValues.title != null) field = 'title'
                    this.searchPackingLists(field)
                } else {
                    this.filterPackingLists()
                }
            },
            updatePackingList (val) {
                let tab = window.open('/v1/packinglists/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
                // router.push({name: 'update_packinglist', params: { packingListId : val}}).catch(err => {})
            },
            viewPdf (packingListId, version) {
                if(version == null){
                    this.dialogs.error = true
                    this.dialogs.error_message = this.$t('message.packingListNotConverted')
                } else {
                    let pl = this.PackingLists.find( pl => pl.Pldo.id == packingListId)
                    let document = encodeURIComponent((pl.Pldo.Contract.version != null ? [pl.Pldo.Contract.title, pl.Pldo.Contract.revision_no , pl.Pldo.Contract.partial_no].filter(Boolean).join('-') : pl.Pldo.Contract.title) + '_PL')
                    let user = JSON.parse(localStorage.getItem('user'))
                    let tab = window.open(
                        process.env.VUE_APP_PDF_SERVER
                        + '/api/render/'
                        + document
                        + '?url='
                        + process.env.VUE_APP_BASE_URL
                        + '/v1/packinglists/print/'
                        + packingListId
                        + '/'
                        + uuidv4()
                        + '&waitFor=h1&pdf.margin.top=15mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0&pdf.landscape=true'
                        , "_blank");
                    tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                    this.openedTabs.push(tab.name)
                }
            },
        },
        watch: {
            // filterOptions: {
            //     handler(){
            //         if(!this.loading.search) {
            //             this.loadPackingLists()
            //         }
            //     },
            //     deep: true
            // },
            // tableOptions: {
            //     handler(){
            //         this.loadPackingLists()
            //     },
            //     deep: true
            // },
        },
        created(){
            this.loading.packinglists = true
            this.searchValues.pl = null
            this.searchValues.title = null
            this.filterValues.buyer = null

            this.loadPackingLists()

            if(this.filterItems.buyer.length === 0) this.loadFilterItems('buyer')
        },
        mounted() {
            const dataTableElement = document.querySelector('#packingListTable > .v-data-table__wrapper > table > tbody')
            const resizeObserver = new ResizeObserver(entries => {
                for (const entry of entries) {
                    const contentBoxSize = entry.contentBoxSize[0];
                    // console.log(contentBoxSize.blockSize)
                    if(contentBoxSize.blockSize >= 40) {
                        if (this.loading.packinglists) this.loading.packinglists = false;
                        resizeObserver.disconnect();
                    }
                }
            });
            resizeObserver.observe(dataTableElement);
        }
    }
</script>

<style>
.filter-width-200 {
    width: 200px !important;
    max-width: 200px !important;
}
.font-xs {
    font-size: 0.60rem;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
</style>